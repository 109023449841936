import React, { Component } from 'react'
import { Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'

//User CSS
import './Dashboard.css'

//User components
import DashboardSiteDeviceCellDatum from './DashboardSiteDeviceCellDatum'
import DashboardBulkInputs from './DashboardBulkInputs'
import Spinner from '../../indicators/spinner.js'
import constants from '../../../config/constants.js'

import api from '../../util/api.js'

const { getSiteLiveData, axiosPostThermostatBulkCommandsPromise } = api

function dlv(obj, key, def, p, undef) {
	key = key.split ? key.split('.') : key;
	for (p = 0; p < key.length; p++) {
		obj = obj ? obj[key[p]] : undef;
	}
	return obj === undef ? def : obj;
}

function averageHs(arr, key) {
	// Check if the array is not empty
	if (arr.length === 0) {
			return 0;
	}

	// Sum up the hs values
	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
			sum += parseFloat(arr[i][key]);
	}

	// Calculate the average
	let average = sum / arr.length;

	return Math.ceil(average);
}

/* Order devices if they have slave devices */
function orderDevices(devices) {
  const deviceMap = new Map()
  const ordered = []
  
  // Map each device by its macID for easy lookup
  devices.forEach(device => deviceMap.set(device.macID, device))

  // Add each device, placing parents followed by their slaves
  devices.forEach(device => {
    if (device.isParent) {
      // Add parent to ordered list
      ordered.push(device)
      // Add each slave directly after the parent
      device.deviceSlaves.forEach(slaveMacID => {
        const slaveDevice = deviceMap.get(slaveMacID)
        if (slaveDevice) ordered.push(slaveDevice)
      })
    } else if (!device.parentMac) {
      // Add standalone devices without a parent
      ordered.push(device)
    }
  })
  
  return ordered
}

function filterValidDevices(deviceLiveData) {
    // console.log('deviceLiveData', deviceLiveData)
    const validDeviceTypes = [ 'AQ TstatPlus', 'TstatPro', 'AQ Tstat', 'AQ Indoor', 'TstatProPlus' ]
    return validDeviceTypes.includes(deviceLiveData.dt)
}

class DashboardSiteTable extends React.Component {
  static isPrivate = true

  constructor(props) {
    super(props)
    this.state = {
      devicesSelected: [],
      selectAllChecked: false,
      apiCalling: false,
      failCalling: false,
    }
  }

  componentDidMount() {
    this.getSiteLiveData()
    setInterval(
      function () {
        this.getSiteLiveData()
      }.bind(this), //Bind this to the timeout so the context is correct
      30000,
    )
  }

  getSiteLiveData = () => {
    //Get the livedata for this specific site
    // console.log('this.props.resolvedPortalSite', this.props.resolvedPortalSite.resolvedSiteDevices)
    if (this.props.resolvedPortalSite) {
      const devices = this.props.resolvedPortalSite.resolvedSiteDevices || []
      getSiteLiveData(this.props.resolvedPortalSite._id)
        .then((res) => res.json())
        .then((response) => {
          //Successful get site live data
          //console.log("getSiteLIVEDATA response:", response);
          console.log(response)
          if (response && response.success === true && response.data && response.data.devicesLiveData) {
            console.log('devicesLiveData', response.data.devicesLiveData)
            for (var i = 0; i < response.data.devicesLiveData.length; i++) {
              response.data.devicesLiveData[i].name = this.props.resolvedPortalSite.resolvedSiteDevices[i].name
            }
						const averageHeatSet = averageHs(response.data.devicesLiveData, 'hs')
						const averageColdSet = averageHs(response.data.devicesLiveData, 'cs')
            
            let slaves = {}
            let liveData = response.data.devicesLiveData.map((d, i) => {
              let deviceSlaves = []
              let isParent = false
              const deviceData = devices[i] || {}
              const macID = deviceData.mac_address
              console.log(`macID ${macID}`, d)
              
              if (d.cnfg_zn_2 && d.cnfg_zn_2.mac) {
                slaves[d.cnfg_zn_2.mac] = macID
                deviceSlaves.push(d.cnfg_zn_2.mac)
                isParent = true
              }

              if (d.cnfg_zn_3 && d.cnfg_zn_3.mac) {
                slaves[d.cnfg_zn_3.mac] = macID
                deviceSlaves.push(d.cnfg_zn_3.mac)
                isParent = true
              }

              if (d.cnfg_zn_4 && d.cnfg_zn_4.mac) {
                slaves[d.cnfg_zn_4.mac] = macID
                deviceSlaves.push(d.cnfg_zn_4.mac)
                isParent = true
              }

              return Object.assign(d, { macID: macID, deviceSlaves, isParent })
            })

            const slaveMacs = Object.keys(slaves)
            if (slaveMacs.length > 0) {
              liveData = liveData.map((d, i) => {
                const deviceData = devices[i] || {}
                const macID = deviceData.mac_address
                if (!slaves[macID]) {
                  return d
                }
                return Object.assign(d, {
                  parentMac: slaves[macID],
                  isSlave: true
                })
              })
            }

            this.setState({
              lastDevicesLiveData: this.state.devicesLiveData,
              devicesLiveData: orderDevices(liveData),
							averageHeatSet: String(averageHeatSet),
							averageColdSet: String(averageColdSet),
            })
          } else {
            // Unsuccessful login
            this.setState({
              lastDevicesLiveData: this.state.devicesLiveData,
              devicesLiveData: [],
            })
            // TODO: handle missing resolvedPortalSites
            //console.log("unsuccessful");
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  getTableCellHtmlFromKeyArray(deviceLiveData, keyArray, deviceIndex, processingFunction, preProcessingFunction) {
    var obj = deviceLiveData
    for (var i = 0; i < keyArray.length; i++) {
      var key = keyArray[i]
			
			if (key.indexOf('.') !== -1) {
				obj = dlv(deviceLiveData, key)
			} else if (typeof obj === 'object' && obj.hasOwnProperty(key)) {
        obj = obj[key]
      } else {
        return <td style={{ textAlign: 'center' }}>N/A</td>
      }
    }

    if (preProcessingFunction !== undefined) {
      var preProcessedObj = preProcessingFunction(obj)
      obj = preProcessedObj.value
      var preProcessedColor = preProcessedObj.color
    }

    //At this point we have determined that the value exists in the dataset, so lets return the cell html with the value in it
    //If a parsing function isn't provided then default parse it with parseStandardZipValue()
    var isNovelValue = this.checkValueIsNovel(deviceIndex, keyArray, obj)

    return (
      //no
      <DashboardSiteDeviceCellDatum color={preProcessedColor ? preProcessedColor : undefined}>
        {processingFunction ? processingFunction(obj) : this.parseStandardZipValue(obj)}
      </DashboardSiteDeviceCellDatum>
    )
  }

  getSimpleCell(value) {
    return (
      //no
      <DashboardSiteDeviceCellDatum>
        {value}
      </DashboardSiteDeviceCellDatum>
    )
  }

  getCellStyle(isNovelValue) {
    if (isNovelValue)
      return {
        color: 'red',
        backgroundColor: 'blue',
        transition: 'color 3s linear',
        transition: 'backgroundColor 3s linear 1s',
      }
    else return {}
  }

  getCellStyleClasses(isNovelValue) {
    if (isNovelValue) return 'novel-value-fade-in novel-value-fade-out'
    else return ''
  }

  parseStandardZipValue(valueString) {
		// console.log('parseStandardZipValue valueString', valueString)
    if (valueString === '8888.00') {
			return 'N/A'
		}
    return valueString
  }

  parseAlarmString(alarmString) {
    switch (alarmString) {
      default:
        return alarmString
    }
  }

  appendUnitAndDegreeToFahrenheitTemperatureString(tempString) {
    return `${tempString} ${String.fromCharCode(176)}F`
  }

  parseTemperatureStringToFahrenheitString(celsiusString) {}

  convertDatetimeToOnlineOfflineString(datetimeString) {
    console.log('convertDatetimeToOnlineOfflineString datetimeString', datetimeString)
    var datetime = new Date(datetimeString)
    var nowDatetime = new Date()
    // If data point within last 10 minutes, device is online
    if (nowDatetime.getTime() - datetime.getTime() > 600000)
      //if its been 10 minutes (in ms) since last datetime then the device is offline
      return {
        value: 'OFFLINE',
        color: 'red',
      }
    else
      return {
        value: 'ONLINE',
        color: 'green',
      }
  }

  checkValueIsNovel(deviceIndex, keyArray, newValue) {
    //First set of data is not considered novel
    if (!this.state.lastDevicesLiveData || !this.state.lastDevicesLiveData[deviceIndex]) return true

    //Otherwise, lets see if the value is different from before
    var obj = this.state.lastDevicesLiveData[deviceIndex]
    for (var i = 0; i < keyArray.length; i++) {
      var key = keyArray[i]

      if (obj.hasOwnProperty(key)) {
        obj = obj[key]
      }
      //If the value was not contained within the previous set of data, this is novel data
      else {
        return true
      }
    }

    //At this point we can compare the old value to the new value
    if (newValue === obj) {
      //console.log(newValue + "===" + obj);
      return false
    } else {
      //console.log(newValue + "!==" + obj);
      return true
    }
  }

  parseModeToTranslatedString(modeIntString) {
    var modeInt = parseInt(modeIntString)
    switch (modeInt) {
      case 1:
        return 'Unoccupied'
      case 2:
        return 'Auto'
      case 3:
        return 'Manual'
      case 4:
        return 'Factory Test'
      case 5:
        return 'Acceptance Tests'
      case 6:
        return 'Free Cooling'
      case 7:
        return 'Int. Cooling'
      case 8:
        return 'Mech. Cooling'
      case 9:
        return 'DCV'
      case 10:
        return 'Ventilation'
      case 11:
        return 'Heating'
      case 12:
        return 'Unoccupied'
      case 13:
        return 'Purge'
      case 14:
        return 'Freeze Protection'
      case 15:
        return 'Brownout'
      case 16:
        return 'PCB Test'
      case 17:
        return 'Recovery'
      case 18:
        return 'Damper Scaling'
      default:
        return 'Unoccupied'
    }
  }

  generateThermostatHtml() {}

  toggleSelectDevice = (e, index, deviceLiveData) => {
    var deviceId = deviceLiveData.device_id
    /*
		console.log("this.refs:");
		console.log(this.refs);
		console.log(e);
		console.log("index: " + index);
		console.log(deviceLiveData);
		
		console.log("deviceId: " + deviceId);
		*/

    //Remove the device from the selected devices list if it is already in there
    for (var i = 0; i < this.state.devicesSelected.length; i++) {
      if (this.state.devicesSelected[i] == deviceId) {
        //var newDevicesSelected = this.state.devicesSelected.splice(i, 1);
        var newDevicesSelected = this.state.devicesSelected.filter((item) => item !== deviceId)
        console.log('newDevicesSelected')
        console.log(newDevicesSelected)
        this.setState({
          devicesSelected: newDevicesSelected,
        })
        return
      }
    }

    //Should only run if it wasn't there already, add it to selected
    var newDevicesSelected = this.state.devicesSelected.concat(deviceId)
    console.log('newDevicesSelected')
    console.log(newDevicesSelected)
    this.setState({
      devicesSelected: newDevicesSelected,
    })
  }

  toggleSelectAllDevices = (e) => {
    console.log('e.target.value: ')
    console.log(e.target.value)
    console.log('checked: ')
    console.log(e.target.checked)

    if (e.target.checked) {
      var devicesSelected = []
      this.state.devicesLiveData
        .filter((deviceLiveData) => deviceLiveData.deviceType === 1)
        .map((deviceLiveData) => {
          devicesSelected.push(deviceLiveData.device_id)
        })
      //console.log("selected:");
      //console.log(devicesSelected);
      this.setState({
        selectAllChecked: false,
        devicesSelected: devicesSelected,
      })
      for (var key in this.refs) {
        if (this.refs.hasOwnProperty(key)) {
          this.refs[key].checked = true
        }
      }
    } else {
      //console.log("clearing devicesSelected");
      this.setState({
        selectAllChecked: true,
        devicesSelected: [],
      })
      for (var key in this.refs) {
        if (this.refs.hasOwnProperty(key)) {
          this.refs[key].checked = false
        }
      }
    }
  }

  submitBulkEdit = (e, options) => {
    if (this.state.devicesSelected.length == 0) {
      alert('You must select at least one device.')
      return
    }

    //Make sure at least one of the checkboxes was checked
    console.log('submit bulk edit options, options:', options)
    if (
      !options.hasOwnProperty('mode') &&
      !options.hasOwnProperty('fan_mode') &&
      !options.hasOwnProperty('cool_setpoint') &&
      !options.hasOwnProperty('heat_setpoint') &&
      !options.hasOwnProperty('schedule')
    ) {
      alert('You must enable at least one command.')
      return
    }

    this.setState({
      apiCalling: true,
      failCalling: false,
    })

    var cool_setpoint_int = parseInt(options.cool_setpoint)
    var heat_setpoint_int = parseInt(options.heat_setpoint)

    if (options.hasOwnProperty('cool_setpoint') && (isNaN(cool_setpoint_int) || !Number.isInteger(cool_setpoint_int))) {
      alert('cool_setpoint must be an integer cast to string')
      return
    } else if (
      options.hasOwnProperty('heat_setpoint') &&
      (isNaN(heat_setpoint_int) || !Number.isInteger(heat_setpoint_int))
    ) {
      alert('heat_setpoint must be an integer cast to string')
      return
    } else {
      //console.log(e.target);
      //console.log(this.state.devicesSelected);

      /*
			var commands = {
				mode: options.mode,
				fan_mode: options.fan_mode,
				cool_set: cool_setpoint_int,
				heat_set: heat_setpoint_int,
				schedule: options.schedule,//schedule ID
			};
			*/
      var thermostatModeKeyName = constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE
      var humanReadableModeKeyName = constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[thermostatModeKeyName]
      //TODO: [LATP-61] add more human readable names here, maybe just send up the thermostat command keys
      var commands = {
        //...(options.hasOwnProperty(humanReadableModeKeyName) && {humanReadableModeKeyName: options[humanReadableModeKeyName]}),
        ...(options.hasOwnProperty('mode') && { mode: options.mode }),
        ...(options.hasOwnProperty('fan_mode') && { fan_mode: options.fan_mode }),
        ...(options.hasOwnProperty('cool_setpoint') && { cool_set: options.cool_setpoint }),
        ...(options.hasOwnProperty('heat_setpoint') && { heat_set: options.heat_setpoint }),
        ...(options.hasOwnProperty('schedule') && { schedule: options.schedule }),
      }

      console.log('commands:')
      console.log(commands)

      console.log('tstat selected:')
      console.log(this.state.devicesSelected)

      var devicesSelected = this.state.devicesSelected
      axiosPostThermostatBulkCommandsPromise(devicesSelected, commands)
        .then((response) => {
          //Successful post bulk commands
          console.log('bulk commands response:')
          console.log()
          if (response && response.data && response.data.success === true) {
            console.log('success')
            console.log(response)
            this.setState({
              apiCalling: false,
              failCalling: false,
            })
          }
          //Unsuccessful
          else {
            console.log('failure')
            alert(response.data.message)
            this.setState({
              apiCalling: false,
              failCalling: true,
            })
          }
        })
        .catch((err) => {
          console.log(err)
          alert(err.message)
          this.setState({
            apiCalling: false,
            failCalling: true,
          })
        })

      /*
			fetch("/thermostat/bulkcommand", {
				method: 'POST',
				headers: {
					Authorization: localStorage.getItem('token')
				},
				body: JSON.stringify({
					thermostatIds: this.state.devicesSelected,
					commands: commands
				})
			})
			.then(res => res.json())
			.then(response => {
				//Successful login
				if(response && response.success === true){
					console.log(response);
				}
				//Unsuccessful login
				else{
					//{success: true, code: 201}
					//console.log(response);
					if(response && response.message)
						alert(response.message);
					else
						alert("There was a problem with your request");
				}

				this.setState({
					apiCalling: false
				});
			})
			.catch((err) => {
				this.setState({
					apiCalling: false,
					failCalling: true
				});
				console.log("handled error");
				console.log(err);
			});
		*/
    }
  }
  render() {
		console.log('this.state.averageHeatSet', this.state.averageHeatSet)

    const validDevices = (this.state.devicesLiveData || []).filter(filterValidDevices)

    if (validDevices.length === 0) {
      return (
        <Table hover key={this.props.resolvedPortalSite ? this.props.resolvedPortalSite._id + '_live-data' : ''}>
          {console.log('this.state.devicesLiveData: ', this.state.devicesLiveData)}
          {this.state.hasOwnProperty('devicesLiveData') &&
            this.state.devicesLiveData.filter((deviceLiveData) => deviceLiveData.deviceType === 3).length == 0 &&
            this.state.devicesLiveData.filter((deviceLiveData) => deviceLiveData.deviceType === 1).length == 0 && (
              <React.Fragment>
                <Row>
                  <h1 style={{ textAlign: 'center' }}>
                    There are no devices in this site with live data
                  </h1>
                </Row>
                {/*
							<Row style = {{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<Link to={`/site/${this.props.resolvedPortalSite._id}/sitemanager`}>
									<Button className={'btn-primary'}>Add Devices</Button>
								</Link>
							</Row>
							*/}
              </React.Fragment>
            )}
          {false && this.state.hasOwnProperty('devicesLiveData') &&
            this.state.devicesLiveData.filter((deviceLiveData) => deviceLiveData.deviceType === 3).length > 0 && (
              <React.Fragment>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>ZipPRO Name</th>
                    <th style={{ textAlign: 'center' }}>Online/Offline</th>
                    <th style={{ textAlign: 'center' }}>Alarm</th>
                    <th style={{ textAlign: 'center' }}>CO2</th>
                    <th style={{ textAlign: 'center' }}>Md</th>
                    <th style={{ textAlign: 'center' }}>OAE</th>
                    <th style={{ textAlign: 'center' }}>OAH</th>
                    <th style={{ textAlign: 'center' }}>OAT</th>
                    <th style={{ textAlign: 'center' }}>RAE</th>
                    <th style={{ textAlign: 'center' }}>RAH</th>
                    <th style={{ textAlign: 'center' }}>RAT</th>
                    <th style={{ textAlign: 'center' }}>SAT</th>
                    <th style={{ textAlign: 'center' }}>ZIP</th>
                    <th style={{ textAlign: 'center' }}>CC1</th>
                    <th style={{ textAlign: 'center' }}>CC2</th>
                    <th style={{ textAlign: 'center' }}>Y1</th>
                    <th style={{ textAlign: 'center' }}>Y2</th>
                    <th style={{ textAlign: 'center' }}>G</th>
                    <th style={{ textAlign: 'center' }}>W</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.hasOwnProperty('devicesLiveData') &&
                    this.state.devicesLiveData
                      .filter((deviceLiveData) => deviceLiveData.deviceType === 3)
                      .map((deviceLiveData, i) => {
                        //TODO: make the datetime in this tooltip relative to the devices timezone (or at very least the user's timeozne)
                        var tooltip = <Tooltip id='updated'>Updated: {new Date(deviceLiveData.datetime).toLocaleString()}<br />Mac: {deviceLiveData.macID}</Tooltip>
                        //TODO: make this support all device types. currently zips are assumed as wimes
                        //TODO: make it so cells only rerender on changed data, or if a device was added / removed (then need to redraw whole table)
                        return (
                          <OverlayTrigger placement='bottom' overlay={tooltip} key={i}>
                            <tr data-mac-id={deviceLiveData.macID}>
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['name'], i)}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                ['datetime'],
                                i,
                                undefined,
                                this.convertDatetimeToOnlineOfflineString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                ['read_data', 'zipdata', 'Alarm'],
                                i,
                                this.parseAlarmString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'CO2'], i)}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                ['read_data', 'zipdata', 'Md'],
                                i,
                                this.parseModeToTranslatedString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'OAE'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'OAH'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'OAT'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'RAE'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'RAH'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'RAT'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'SAT'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'Zip'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'CC1'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'CC2'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'Y1'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'Y2'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'G'], i)}
                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['read_data', 'zipdata', 'W'], i)}
                            </tr>
                          </OverlayTrigger>
                        )
                      })}
                </tbody>
                {/*Puts a space in between the zippro section and thermostat section*/}
              </React.Fragment>
            )}
        </Table>
      )
    }
    console.log('xxx', this.state.devicesLiveData)
    return (
      <React.Fragment>
        <Table hover key={this.props.resolvedPortalSite ? this.props.resolvedPortalSite._id + '_live-data-2' : ''}>
          {this.state.hasOwnProperty('devicesLiveData') &&
            this.state.devicesLiveData.filter(filterValidDevices).length > 0 && (
              <React.Fragment>
                <thead>
                  <tr>
                    {/*TODO: confirm with jan what thermostat data we want to display*/}
                    <th style={{ textAlign: 'center' }}>
                      <input type='checkbox' onClick={(e) => this.toggleSelectAllDevices(e)} />
                      <Row>Select All</Row>
                      
                    </th>
                    <th style={{ textAlign: 'center' }}>Device Label</th>
                    <th style={{ textAlign: 'center' }}>Device Type</th>
                    <th style={{ textAlign: 'center' }}>Online/Offline</th>
                    <th style={{ textAlign: 'center' }}>Op State</th>
                    <th style={{ textAlign: 'center' }}>Humidity</th>
                    <th style={{ textAlign: 'center' }}>Fan State</th>
                    <th style={{ textAlign: 'center' }}>Cool Set</th>
                    <th style={{ textAlign: 'center' }}>Heat Set</th>
                    <th style={{ textAlign: 'center' }}>Hold</th>
                    <th style={{ textAlign: 'center' }}>Zone Temp</th>
                    <th style={{ textAlign: 'center' }}>Lock</th>
                    <th style={{ textAlign: 'center' }}>CO2</th>
                    <th style={{ textAlign: 'center' }}>Duty</th>
                    <th style={{ textAlign: 'center' }}>Occupied</th>
                    <th style={{ textAlign: 'center' }}>Modify</th>
                    <th style={{ textAlign: 'center' }}>Data Analysis</th>
                    {/*TODO: add CO2? relays? mssage?*/}

                    {/*
										Use an online JSON formatter to see this kind of data
										{_id: "5a7e1c3d87826edd316806f5", mac_address: "80-A5-89-36-58-0F", device_id: {…}, tstat_read_data: {…}, button_pressed: "FALSE", …} button_pressed : "FALSE" datetime : "2018-02-09T22:10:05.000Z" deviceType : 1 device_id : {$ref: "device", $id: "5940b1e0e873a6df7d909699", $db: ""} mac_address : "80-A5-89-36-58-0F" state : {type: "G", desc: ""} tstat_read_data : CO2_1 : "0" CO2_1_RSSI : "0" CO2_2 : "0" CO2_2_RSSI : "0" CO2_3 : "0" CO2_3_RSSI : "0" CO2_4 : "0" CO2_4_RSSI : "0" cool_set : "84.0" fan_mode : "AUTO" fan_state : "IDLE" heat_set : "73.0" op_state : "OFF" relay_state : relay1 : "ON" relay2 : "OFF" relay3 : "OFF" relay4 : "OFF" relay5 : "OFF" relay6 : "OFF" relay7 : "OFF" __proto__ : Object temp_hold : "DISABLE" tstat_clock : {current_time: "14:09", current_day: "4"} tstat_mode : "HEAT" tstat_msg : "" zone_temp : "77.3" __proto__ : Object _id : "5a7e1c3d87826edd316806f5"*/}
                  </tr>
                </thead>

                <tbody>
                  {
                    //let resolvedSiteDevices = this.props.resolvedSiteDevices;
                    //console.log(this.props.resolvedSiteDevices);
                    this.state.devicesLiveData
                      .filter(filterValidDevices)
                      .map((deviceLiveData, i) => {
                        console.log(`deviceLiveData ${deviceLiveData.macID}`, deviceLiveData)
                        const label = deviceLiveData.lbl || deviceLiveData.name
                        const isSlave = deviceLiveData.isSlave
                        let analysisLink = ''
                        if (isSlave) {
                          const parentItem = this.state.devicesLiveData.find(d => d.macID === deviceLiveData.parentMac) 
                          if (parentItem) {
                            analysisLink = `?parent=${parentItem.device_id}`
                          }
                        }

                        //TODO: make the datetime in this tooltip relative to the devices timezone (or at very least the user's timeozne)
                        var tooltip = (
                          <Tooltip id='last-updated'>
                            Updated: {new Date(deviceLiveData.datetime).toLocaleString()} <br />Mac: {deviceLiveData.macID}
                          </Tooltip>
                        )
                        // TODO: make this support all device types. currently zips are assumed as wimes
                        // TODO: make it so cells only rerender on changed data, or if a device was added / removed (then need to redraw whole table)
                        return (
                          <OverlayTrigger placement='bottom' overlay={tooltip} key={`overlay-${i}`}>
                            <tr data-mac-id={deviceLiveData.macID}>
                              {/*Checkbox table cell for bulk operations*/}
                              <DashboardSiteDeviceCellDatum>
                                {(() => {
                                  var foundDevice = false
                                  this.state.devicesSelected.map((deviceSelected) => {
                                    if (deviceSelected == '') {
                                      foundDevice = true
                                      return
                                    }
                                  })
                                  if (foundDevice) {
                                    return (
                                      <div>
                                        {React.createElement('input', {
                                          type: 'checkbox',
                                          defaultChecked: true,
                                          ref: deviceLiveData.device_id,
                                          onClick: (e) => {
                                            this.toggleSelectDevice(e, i, deviceLiveData)
                                          },
                                        })}
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div>
                                        {React.createElement('input', {
                                          type: 'checkbox',
                                          defaultChecked: false,
                                          ref: deviceLiveData.device_id,
                                          onClick: (e) => {
                                            this.toggleSelectDevice(e, i, deviceLiveData)
                                          },
                                        })}
                                      </div>
                                    )
                                  }
                                })()}
                              </DashboardSiteDeviceCellDatum>

                              {/*When Read from DB*/}
                              {/* {this.getTableCellHtmlFromKeyArray(deviceLiveData, ["name"], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["datetime"], i, undefined, this.convertDatetimeToOnlineOfflineString)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE]], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_MODE]], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_STATE]], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]], i, this.appendUnitAndDegreeToFahrenheitTemperatureString)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]], i, this.appendUnitAndDegreeToFahrenheitTemperatureString)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HOLD]], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE]], i, this.appendUnitAndDegreeToFahrenheitTemperatureString)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.LOCK]], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_read_data", constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR], constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]], i)}
												{this.getTableCellHtmlFromKeyArray(deviceLiveData, ["occupied"], i)} */}

                              {/* When read from shadow */}
                              {/* {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['name'], i)} */}
                              {this.getSimpleCell(
                                (isSlave) ? `↳ ${label}` : label 
                              )}

                              {this.getTableCellHtmlFromKeyArray(deviceLiveData, ['dt'], i)}
                            
                              {/*TODO:This is really hacky. Should just make a new function where I can pass any value I want for a <td></td>*/}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                ['datetime'],
                                i,
                                undefined,
                                this.convertDatetimeToOnlineOfflineString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE /*"op_state"*/],
                                i,
                                (value) => {
                                  if (value === 'OFF') {
                                    return 'Off'
                                  }
                                  if (value === 'C') {
                                    return 'Cool'
                                  }

                                  if (value === 'H') {
                                    return 'Heat'
                                  }
                                  return 'N/A'
                                }
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_RELATIVE_HUMIDITY /*"fan_mode"*/],
                                i,
                                function (rhUnitlessString) {
                                  return rhUnitlessString + '%'
                                },
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_STATE /*"fan_state"*/],
                                i,
                                (value) => {
                                  if (value === 'O') {
                                    return 'On'
                                  }
                                  if (value === 'I') {
                                    return 'Idle'
                                  }
                                  return value
                                }
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT /*"cool_set"*/],
                                i,
                                this.appendUnitAndDegreeToFahrenheitTemperatureString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT /*"heat_set"*/],
                                i,
                                this.appendUnitAndDegreeToFahrenheitTemperatureString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.HOLD] /*"temp_hold"*/,
                                i,
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE] /*"zone_temp"*/,
                                i,
                                this.appendUnitAndDegreeToFahrenheitTemperatureString,
                              )}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.LOCK] /*"lock"*/,
                                i,
                              )}
                              {/* {this.getTableCellHtmlFromKeyArray(deviceLiveData, ["tstat_lockout"], i)} */}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
																['live_zn_1.co2'],
                                // [constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2] /*"scd"*/,
                                i,
                                function (co2UnitlessString) {
                                  if (co2UnitlessString) {
                                    return co2UnitlessString + ' PPM'
                                  }
                                  return 'N/A'
                                },
                              )}
                              {/*co2*/}
                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData,
                                [constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY] /*"scd"*/,
                                i,
                                function (dutyString) {
                                  return dutyString
                                },
                              )}
                              {/*co2*/}

                              {this.getTableCellHtmlFromKeyArray(
                                deviceLiveData, 
                                ['live_zn_1.occ'], 
                                i, 
                                function (string) {
                                  if (!string) return 'N/A'
                                  // capitalize first letter
                                  return string.charAt(0).toUpperCase() + string.slice(1)
                                }
                              )}

                              {/*Link / icon for control*/}

                              <td>
                                {deviceLiveData.hasOwnProperty('portalDeviceId') && (
                                  <Link
                                    to={`/site/${this.props.resolvedPortalSite._id}/device/${deviceLiveData.portalDeviceId}/control`}
                                  >
                                    <i
                                      className='fa fa-cogs fa-fw fa-lg'
                                      style={{ width: '100%' }}
                                      aria-hidden='true'
                                    ></i>
                                  </Link>
                                )}
                              </td>
                              <td>
                                {deviceLiveData.hasOwnProperty('device_id') && (
                                  <Link
                                    to={`/site/${this.props.resolvedPortalSite._id}/device/${deviceLiveData.device_id}/analysis${analysisLink}`}
                                  >
                                    <i
                                      className='fa fa-chart-line fa-fw fa-lg'
                                      style={{ width: '100%' }}
                                      aria-hidden='true'
                                    ></i>
                                  </Link>
                                )}
                              </td>
                            </tr>
                          </OverlayTrigger>
                        )
                      }, this)
                  }
                </tbody>
              </React.Fragment>
            )}
        </Table>
        {this.state.hasOwnProperty('devicesLiveData') &&
          (this.state.devicesLiveData.filter((deviceLiveData) => deviceLiveData.deviceType === 3).length != 0 ||
            this.state.devicesLiveData.filter((deviceLiveData) => deviceLiveData.deviceType === 1).length != 0) && (
            <div>
              {/*
				<Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<Link to={`/site/${this.props.resolvedPortalSite._id}/sitemanager`}>
						<Button className={'btn-primary'}>Add Devices</Button>
					</Link>
				</Row>
				*/}
              <Row>
                <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Bulk Command Thermostats</h3>
              </Row>

              <DashboardBulkInputs
                thermostatschedules={this.props.thermostatschedules}
                handleClick={this.submitBulkEdit}
								averageHeatSet={this.state.averageHeatSet}
								averageColdSet={this.state.averageColdSet}
              >
                <Spinner fail={this.state.failCalling} active={this.state.apiCalling}></Spinner>
              </DashboardBulkInputs>
            </div>
          )}
      </React.Fragment>
    )
  }
}

export default DashboardSiteTable
